import { useCallback } from 'react';

import { Button } from 'atp-react-ui';
import { NavLink } from 'react-router';

import { Container } from '@/_components';
import { useAccountDependenciesContext, useMeContext } from '@/_providers';
import { googleAnalytics4 } from '@/_services';

import { useCoursesLinkActive } from './use-courses-link-active';

import type { INavbar } from '.';

function MobileNav({ secondaryLinks }: INavbar) {
  const { isRewardProgramActive, setReferralModal } = useAccountDependenciesContext();
  const isLinkActive = useCoursesLinkActive();
  const { canAccessPortalFeatures } = useMeContext();

  const handleReferralClick = useCallback(() => {
    googleAnalytics4.event({ category: 'get_referral_link', action: 'get_referral_link' });
    setReferralModal();
  }, [setReferralModal]);

  return (
    <Container className="md:hidden">
      <section className="-mx-3 flex flex-col gap-y-2 px-0 py-1">
        {secondaryLinks.map((item) => (
          <NavLink key={item.path} to={item.path}>
            {({ isActive }) => (
              <Button
                as="dropdownItem"
                className={`text-white hover:bg-gray-overlay/30 hover:text-gray-75 ${
                  isLinkActive(item, isActive) ? 'text-primary-medium' : ''
                }`}
              >
                {item.META.name}
              </Button>
            )}
          </NavLink>
        ))}
        {isRewardProgramActive && canAccessPortalFeatures && (
          <Button
            as="dropdownItem"
            onClick={handleReferralClick}
            className="text-white hover:bg-gray-overlay/30 hover:text-gray-75"
          >
            Refer a friend
          </Button>
        )}
      </section>
    </Container>
  );
}

export default MobileNav;
