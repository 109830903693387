import { useCallback, useEffect, useRef } from 'react';

import { useBoolean } from 'atp-react-ui';
import { useLocation, useSearchParams } from 'react-router';

import { config } from '@/_config';
import { CookieManager, snackbar } from '@/_services';
import { useCurrentSearchParams } from '@/_shared';

import { useFormDef } from './form';
import { useChangeMoodlePassword } from './use-change-moodle-password';

const moodleUrl = import.meta.env.VITE_MOODLE_URL;
const isProduction = import.meta.env.VITE_ENVIRONMENT === 'production';
const moodleLoginUrl = `${moodleUrl}/login/achieve_login.php`;
const validErrors = ['2', '3'];

export function useMoodleAuth({ courseId }: { courseId: string }) {
  const { pathname } = useLocation();
  const [_, setParam] = useSearchParams();

  const moodleLoginUrlWithCallback = `${moodleLoginUrl}?callback_url=https://${isProduction ? '' : 'staging.'}prep.achievetestprep.com${pathname}?course_id=${courseId}`;

  const { searchParams } = useCurrentSearchParams<{ errorcode?: string; course_id?: string }>();
  const { errorcode: errorCode, course_id } = searchParams;

  const submitForm = useBoolean();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const getPassword = useCallback(() => {
    const lastPassword = CookieManager.getLastPassword();
    return lastPassword ?? config.defaultMoodlePassword;
  }, []);

  const username = CookieManager.getLastUsername() ?? '';
  const password = getPassword();

  const form = useFormDef({ username, password });

  const updateForm = useCallback((u: string, p: string) => {
    form.setValue('username', u, { shouldDirty: true });
    form.setValue('password', p, { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(() => {
    submitForm[1].setTrue();
    submitButtonRef.current?.click();
  }, [submitForm]);

  const { mutateAsync: changeMoodlePassword } = useChangeMoodlePassword({
    onSettled: (data) => {
      setParam({});
      submitForm[1].setTrue();
      if (data?.code === 1) {
        updateForm(username, getPassword());
        setTimeout(() => {
          submitButtonRef.current?.click();
        }, 0);
      } else if (data?.code === 0) {
        // User doesn't exist on Moodle
        snackbar.error("We couldn't find your account on Moodle. Please contact support.");
      } else {
        snackbar.error('Unable to open the course resource.');
      }
      submitForm[1].setFalse();
    },
  });

  useEffect(() => {
    if (submitForm[0] || !errorCode || !username || course_id !== courseId) {
      return;
    }
    if (validErrors.includes(errorCode)) {
      submitForm[1].setTrue();
      changeMoodlePassword({ username, password: getPassword() });
    } else {
      snackbar.error('Unable to open the course resource.');
    }
  }, [changeMoodlePassword, errorCode, getPassword, course_id, courseId, submitForm, username]);

  return {
    submitButtonRef,
    moodleLoginUrl: moodleLoginUrlWithCallback,
    form,
    submitForm,
    onSubmit,
  };
}
