import { memo } from 'react';

import ThumbsDown from '@heroicons/react/24/solid/HandThumbDownIcon';
import ThumbsUp from '@heroicons/react/24/solid/HandThumbUpIcon';
import { Button, Card, CardBody, ScrollArea, Text, twMerge } from 'atp-react-ui';
import { Link } from 'react-router';

import { QuestionText } from '@/_components';
import { ROUTES } from '@/_navigation';
import { dateTime } from '@/_shared';

import type { FeedbackStateType } from '../types';

type QuestionProps = {
  isFirst?: boolean;
  isEditable?: boolean;
  isInstructor?: boolean;
  allowFeedback?: boolean;
  isBestAnswer?: boolean;
  parentQuestionId: string;
  course_id?: string;
  question_id: string;
  modified_on: string;
  created_on: string;
  user_name: string;
  title: string;
  body: string;
  feedbackState?: FeedbackStateType;
  feedback?: string | null;
  className?: string;
  handleResolve?: (params: { question_id: string }) => void;
  handleGiveFeedback?: (params: { question_id: string }) => void;
};

function QuestionBase({
  isFirst = false,
  isEditable = false,
  isInstructor = false,
  allowFeedback = false,
  isBestAnswer = false,
  course_id,
  parentQuestionId,
  question_id,
  modified_on,
  created_on,
  user_name,
  title,
  body,
  feedback,
  className,
  feedbackState,
  handleResolve,
  handleGiveFeedback,
}: QuestionProps) {
  const onResolve = () => {
    if (!allowFeedback) {
      return;
    }
    handleResolve?.({ question_id });
  };

  const onGiveFeedback = () => {
    if (!allowFeedback) {
      return;
    }

    handleGiveFeedback?.({ question_id });
  };

  return (
    <article className={twMerge(isFirst ? '' : 'w-1/3 min-w-[66.66666%]', isInstructor ? 'self-end' : '', className)}>
      <Card
        className={twMerge(
          'border border-outline/30 shadow-md',
          isInstructor ? 'rounded-br-none border-primary-light' : 'rounded-bl-none'
        )}
      >
        <CardBody>
          <Text className={twMerge('no-underline', isInstructor ? 'text-green-500' : 'text-primary')} as="overline">
            {title}
          </Text>
          <Text as="caption" className="mt-1 text-accent-light">
            {modified_on ? 'Modified' : 'Posted'} on &nbsp;
            {dateTime.format(modified_on || created_on, 'MM/DD/YYYY [at] hh:mm')} by {user_name}
          </Text>

          <ScrollArea orientation="horizontal" className="w-m-full -mr-6 pr-6">
            <Text as="caption-medium" className="mt-2 text-accent">
              <QuestionText str={body} />
            </Text>
          </ScrollArea>
          {!isInstructor && isEditable && (
            <>
              <div className="my-4 border-b border-outline" />
              <section>
                <Link
                  to={ROUTES.ON_DEMAND_TUTORING_QUESTION_EDIT.buildPath(
                    { id: question_id },
                    {
                      course_id,
                      parent_question_id: parentQuestionId,
                    }
                  )}
                >
                  <Button as="outline" theme="info" size="xs">
                    Edit
                  </Button>
                </Link>
              </section>
            </>
          )}
          {isBestAnswer && (
            <>
              <div className="my-4 border-b border-outline" />
              <Text as="caption-medium-bold" className="font-bold text-green-600">
                Best Answer
              </Text>
            </>
          )}
          {allowFeedback && (
            <>
              <div className="my-4 border-b border-outline" />
              <div className="flex items-center justify-end gap-x-2">
                <Text as="caption" className="text-accent-light">
                  Did this solve your question?
                </Text>
                <div className="flex gap-x-2">
                  <Button
                    icon={ThumbsUp}
                    className="h-6 rounded-2xl border border-green-500 bg-white px-3 py-0"
                    iconClassName={feedback ? 'text-accent-x-light' : 'text-green-500'}
                    onClick={onResolve}
                    disabled={!!feedback}
                    showLoader={
                      feedbackState?.loading &&
                      feedbackState.feedback === 'positive' &&
                      feedbackState.data?.id === question_id
                    }
                  />
                  <Button
                    icon={ThumbsDown}
                    className="h-6 rounded-2xl border border-red-500 bg-white px-3 py-0"
                    iconClassName="text-red-500"
                    onClick={onGiveFeedback}
                    disabled={!!feedback}
                    showLoader={
                      feedbackState?.loading &&
                      feedbackState.feedback === 'negative' &&
                      feedbackState.data?.id === question_id
                    }
                  />
                </div>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </article>
  );
}

export const Question = memo(QuestionBase);
