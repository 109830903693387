import { useCallback, useEffect } from 'react';

import { Outlet, useNavigate } from 'react-router';
import type { CookieChangeListener } from 'universal-cookie';

import { ROUTES } from '@/_navigation';
import { useLocationDetails } from '@/_navigation/hooks/use-location-details';
import { useSalesForceLogin } from '@/_prep-portal/auth/login/api';
import { CookieManager } from '@/_services';

export function RouteRedirectionProvider() {
  const nav = useNavigate();

  useSalesForceLogin();

  const { data: routeData } = useLocationDetails();

  const handleTokenChange = useCallback(
    (accessToken?: string) => {
      if (!routeData?.META?.routeType) {
        return;
      }

      const { routeType } = routeData.META;

      if (!accessToken) {
        if (!routeType.includes('PUBLIC')) {
          nav(ROUTES.LOGIN.path);
        }
      } else if (!routeType.includes('PREP')) {
        nav({ pathname: ROUTES.RESOLVE_LOGIN.path });
      }
    },
    [routeData, nav]
  );

  useEffect(() => {
    const listenCookiesChange: CookieChangeListener = (cookie) => {
      if (CookieManager.matchKeys(cookie.name, CookieManager.ACCESS_TOKEN_KEY)) {
        handleTokenChange(cookie.value);
      }
    };
    CookieManager.cookies.addChangeListener(listenCookiesChange);

    return () => {
      CookieManager.cookies.removeChangeListener(listenCookiesChange);
    };
  }, [nav, handleTokenChange]);

  useEffect(() => {
    handleTokenChange(CookieManager.getAccessToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeData]);

  return <Outlet />;
}
