import { Skeleton } from 'atp-react-ui';

export function Loading() {
  return (
    <article className="relative flex flex-1 flex-col justify-center gap-y-4 pb-10 pt-6">
      <main className="flex flex-1 flex-col justify-center gap-4 py-4 pb-10 pt-6">
        <Skeleton className="h-12 w-3/4" />

        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-8 w-1/2" />
      </main>
    </article>
  );
}
