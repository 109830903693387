import type { PropsWithChildren } from 'react';

import ArrowLeftIcon from '@heroicons/react/24/outline/ArrowLeftIcon';
import { Button } from 'atp-react-ui';
import { Link } from 'react-router';

import { Container } from '@/_components';
import { ROUTES } from '@/_navigation';

export default function Layout({ children }: PropsWithChildren<unknown>) {
  return (
    <section
      className="flex h-screen w-screen items-center justify-center bg-cover bg-[-16rem_center] bg-no-repeat xs:bg-[30%_center] pre-lg:bg-left-top"
      style={{
        backgroundImage: 'url(/images/atp-waiting-desktop.jpg)',
      }}
    >
      <section className="absolute inset-0 bottom-auto">
        <Container className="flex items-center justify-between pt-8">
          <div>
            <Link to={ROUTES.COURSES.path}>
              <Button
                icon={ArrowLeftIcon}
                size="base"
                as="link"
                className="bg-accent/60 text-white hover:bg-white/80"
                iconClassName="size-8"
              />
            </Link>
          </div>
          <div>
            <img src="/images/atp-white-logo.svg" className="h-14" alt="Achieve Test Prep" />
          </div>
        </Container>
      </section>
      {children}
    </section>
  );
}
