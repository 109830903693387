import React from 'react';

import { useSearchParams } from 'react-router';

import { useAuth } from '@/_providers';

export const useSalesForceLogin = () => {
  const [queryParams] = useSearchParams();
  const { login, logout } = useAuth();

  React.useLayoutEffect(() => {
    const accessToken = queryParams.get('access');
    const refreshToken = queryParams.get('refresh');

    if (accessToken && refreshToken) {
      logout();

      setTimeout(() => {
        login({ accessToken, refreshToken, loginMethod: 'url' });
      }, 150);
    }
  }, [queryParams, logout, login]);
};
