import { Navigate, useLocation } from 'react-router';

import { ROUTES } from '@/_navigation';
import { useMeContext } from '@/_providers';

export function ResolveLogin() {
  const { state } = useLocation();
  const { isSelfServe, canAccessPortalFeatures } = useMeContext();

  if (isSelfServe && !canAccessPortalFeatures) {
    return <Navigate to={ROUTES.STUDY_GUIDES_PURCHASED.path} state={state} />;
  }

  return <Navigate to={ROUTES.IN_PROGRESS.path} state={state} />;
}
