import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router';
import type { CookieChangeListener } from 'universal-cookie';

import { SELF_STUDY_ALLOWED_LINKS } from '@/_navigation';
import { setChatNameEmail, useIdentifyHotJar, CookieManager, logger } from '@/_services';
import { isPathAllowed, isRewardsAllowed, RESTRICTIONS_TYPE, shouldShowSelfOnboardingSurvey } from '@/_shared/policies';

import { useLocationDetails } from '../../_navigation/hooks/use-location-details';
import ROUTES from '../../_navigation/routes';
import { useAccountDependenciesContext } from '../account-dependencies-provider';
import { useMeQuery } from '../hooks/use-me-query';
import type { MeApiTransformedResponse } from '../hooks/use-me-query';

import type { MeContextType } from './me-context';
import { MeContext } from './me-context';

export function MeProvider({ children }: { children: ReactNode }) {
  const nav = useNavigate();
  const routeData = useLocationDetails();
  const { data: accountDepData } = useAccountDependenciesContext();
  const { data, isFetching, refetch, isSuccess } = useMeQuery();
  const loginMethod = CookieManager.getLoginMethod();

  const identifyHotJar = useIdentifyHotJar();

  const handleRedirection = useCallback(
    (data: MeApiTransformedResponse) => {
      const { accessType, isStudent, why_bridge_completed, canAccessPortalFeatures } = data;
      const isNotAllowedPath = isPathAllowed(accessType, routeData.data?.META);

      if (!isStudent) {
        // Redirect to instructor courses
        return;
      }

      if (accountDepData?.self_serve && !canAccessPortalFeatures) {
        if (!SELF_STUDY_ALLOWED_LINKS.includes(routeData.data.path as string)) {
          nav(ROUTES.STUDY_GUIDES_PURCHASED.path);
          return;
        }
        return;
      }

      // Self-onboarding survey
      if (
        shouldShowSelfOnboardingSurvey(
          accountDepData?.first_login,
          accountDepData?.has_submitted_onboarding_survey,
          why_bridge_completed
        ) &&
        routeData.data.path !== ROUTES.ONBOARDING_SELF_SURVEY.path
      ) {
        nav(ROUTES.ONBOARDING_SELF_SURVEY.path);
        return;
      }

      if (
        accessType === RESTRICTIONS_TYPE.PPP_ACCESS_BLOCKED &&
        isNotAllowedPath &&
        routeData.data.path !== ROUTES.BILLING.path
      ) {
        nav(ROUTES.BILLING.path);
        return;
      }

      if (
        accessType === RESTRICTIONS_TYPE.PREP_ACCESS_ONLY &&
        isNotAllowedPath &&
        routeData.data.path !== ROUTES.IN_PROGRESS.path
      ) {
        nav(ROUTES.IN_PROGRESS.path);
        return;
      }

      if (
        loginMethod === 'normal' &&
        routeData.data.path &&
        routeData.data.path !== ROUTES.HARDSHIP_PLANS.path &&
        accessType !== RESTRICTIONS_TYPE.PREP_ACCESS_ONLY
      ) {
        if (why_bridge_completed === false && !routeData.data.path.includes('onboarding')) {
          nav(ROUTES.ONBOARDING_WELCOME.path);
          return;
        }
      }

      if (routeData.data.path === ROUTES.REWARDS.path && !isRewardsAllowed(accountDepData?.allow_rewards)) {
        nav(ROUTES.COURSES.path);
      }
    },
    [
      routeData.data?.META,
      routeData.data.path,
      accountDepData?.self_serve,
      accountDepData?.first_login,
      accountDepData?.has_submitted_onboarding_survey,
      accountDepData?.allow_rewards,
      loginMethod,
      nav,
    ]
  );

  useEffect(() => {
    const listenCookiesChange: CookieChangeListener = (cookie) => {
      if (CookieManager.matchKeys(cookie.name, CookieManager.ACCESS_TOKEN_KEY)) {
        if (cookie.value) {
          refetch();
        }
      }
    };
    CookieManager.cookies.addChangeListener(listenCookiesChange);

    return () => {
      CookieManager.cookies.removeChangeListener(listenCookiesChange);
    };
  }, [nav, refetch]);

  useEffect(() => {
    if (data) {
      handleRedirection(data);
      logger.setId({ customer_id: data.id });
    }
  }, [data, handleRedirection]);

  useEffect(() => {
    if (isSuccess && data) {
      setChatNameEmail(data.email, data.name);
      identifyHotJar(data);
      CookieManager.setLastUsername(data.email);
    }
  }, [data, identifyHotJar, isSuccess]);

  const value: MeContextType = useMemo(
    () => ({
      data,
      isSelfServe: !!(data?.isStudent && accountDepData?.self_serve),
      canAccessPortalFeatures: !!data?.canAccessPortalFeatures,
      isLoadingMeData: isFetching,
      isFetching,
    }),
    [accountDepData?.self_serve, data, isFetching]
  );

  return <MeContext.Provider value={value}>{children}</MeContext.Provider>;
}
