import type { WalkthoughStep } from '@/_components';
import { Walkthrough } from '@/_components';
import { ROUTES } from '@/_navigation';
import { useWalkthroughContext } from '@/_providers';
import { pathToClassName, useIsMounted } from '@/_shared';

const steps: WalkthoughStep[] = [
  {
    target: `.course-tab-${pathToClassName(ROUTES.UNREGISTERED.relativePath)}`,
    content: (
      <>
        In the <span className="font-bold">Unregistered</span> section, you can view all your available courses and
        register with your desired course/s.
      </>
    ),
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: `.course-tab-${pathToClassName(ROUTES.IN_PROGRESS.relativePath)}`,
    content: (
      <>
        In the <span className="font-bold">In Progress</span> section, you can join your live lessons and view your
        course resources from this section.
      </>
    ),
    disableScrolling: true,
  },
  {
    target: `.course-tab-${pathToClassName(ROUTES.COMPLETE.relativePath)}`,
    content: (
      <>
        In the <span className="font-bold">Completed</span> section, you can register for an exam or submit your exam
        result from this section.
      </>
    ),
    disableScrolling: true,
    locale: {
      last: 'Continue',
    },
  },
];

export function CoursesTabWalkthrough() {
  const ready = useIsMounted({ delay: 500 });

  const { setWalkthroughViewed } = useWalkthroughContext();

  return (
    <Walkthrough
      run
      steps={steps}
      continuous
      onComplete={setWalkthroughViewed('COURSE_TABS')}
      ready={ready}
      showSkipButton
    />
  );
}
