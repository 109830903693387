import * as z from 'zod';

import { dateTime } from '../utils';

export const FormValidation = {
  has_ssn: z.boolean(),
  email: z.string().email(),
  password: z.string().min(4),
  requiredString: z.string().refine((value) => value.length > 0, 'This field is required.'),
  selectInput: (params: { isOptional?: boolean; error?: string } = { isOptional: false, error: '' }) =>
    z
      .object({ name: z.string(), value: z.string() })
      .refine(({ value }) => params.isOptional || value.length > 0, params.error || 'This field is required.'),
  zipCode: z.string().length(5),
  preferredTime: z
    .string()
    .refine((value) => value.split(';').length >= 3, 'Select at least three preferred time slots.'),
  birthdate: z
    .object(
      {
        startDate: z.date({ required_error: 'Invalid Birthdate', invalid_type_error: 'Invalid Birthdate' }),
        endDate: z.date({ required_error: 'Invalid Birthdate', invalid_type_error: 'Invalid Birthdate' }),
      },
      { required_error: 'Invalid Birthdate', invalid_type_error: 'Invalid Birthdate' }
    )
    .nullable()
    .refine((value) => value !== null && dateTime.parse(value.startDate).isValid(), 'Invalid Birthdate'),
  ssn: z.string(),

  get preferredContactFrequency() {
    return this.selectInput({ isOptional: false, error: 'Please provide a preferred contact time.' });
  },
  get gender() {
    return this.selectInput({ isOptional: false, error: 'Please select your gender' });
  },
  get education() {
    return this.selectInput({ isOptional: false, error: 'Please select your education level' });
  },
  get salary() {
    return this.selectInput({ isOptional: false, error: 'Please select your annual salary bracket' });
  },
  achAccount: z
    .string()
    .refine((value) => value.length >= 6 && value.length <= 17, 'Please enter a valid account number.'),
  achRouting: z.string().length(9, 'Please enter a valid routing number.'),
  creditCard: z.string().length(16, 'Please enter a valid credit card number.'),
  cvv: z.string().length(3, 'Please enter a valid CVV.'),
  mobilePhone: z.string().length(10),
};
