import type { WalkthoughStep } from '@/_components';
import { Walkthrough } from '@/_components';
import { ROUTES } from '@/_navigation';
import { useWalkthroughContext } from '@/_providers';
import { pathToClassName, useIsMounted } from '@/_shared';

const steps: WalkthoughStep[] = [
  {
    target: `.course-tab-${pathToClassName(ROUTES.COMPLETE.relativePath)}`,
    content: (
      <>
        In the <span className="font-bold">Completed</span> section, you can register for an exam or submit your exam
        result from this section.
      </>
    ),
    disableScrolling: true,
    locale: {
      last: 'Continue',
    },
  },
  {
    target: `.${pathToClassName(ROUTES.COMPLETE.relativePath)} .prep-course-card`,
    content: 'Completed Courses will appear under the Completed section.',
    disableBeacon: true,
    disableScrolling: false,
  },
  {
    target: `.${pathToClassName(ROUTES.COMPLETE.relativePath)} .schedule-exam-button`,
    content: 'You can schedule an exam by clicking on the Schedule Exam button.',
    disableScrolling: false,
    locale: {
      last: 'Finish',
    },
  },
];

export function PassCourseWalkthrough() {
  const ready = useIsMounted({ delay: 500 });

  const { setWalkthroughViewed } = useWalkthroughContext();

  return (
    <Walkthrough
      run
      steps={steps}
      continuous
      onComplete={setWalkthroughViewed('PASS_COURSE')}
      ready={ready}
      showSkipButton
    />
  );
}
