import { Container } from '@/_components';

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <Container className="flex h-screen flex-col">
      {/* <Header /> */}
      {children}
    </Container>
  );
}
