import type { WalkthoughStep } from '@/_components';
import { Walkthrough } from '@/_components';
import { ROUTES } from '@/_navigation';
import { useWalkthroughContext } from '@/_providers';
import { pathToClassName } from '@/_shared';

const steps: WalkthoughStep[] = [
  {
    target: `.course-tab-${pathToClassName(ROUTES.UNREGISTERED.relativePath)}`,
    content: (
      <>
        In the <span className="font-bold">Unregistered</span> section, you can view all your available courses and
        register with your desired course/s.
      </>
    ),
    disableBeacon: true,
    disableScrolling: true,
  },
  {
    target: `.${pathToClassName(ROUTES.UNREGISTERED.relativePath)} .prep-course-card`,
    content: 'This is a course that is available for registration.',
    disableBeacon: true,
    spotlightClicks: false,
    disableScrolling: true,
  },
  {
    target: `.${pathToClassName(ROUTES.UNREGISTERED.relativePath)} .prep-course-card-register-button`,
    content: 'Click this button to register for the course',
    spotlightClicks: true,
    disableScrolling: true,
    locale: {
      last: 'Continue',
    },
  },
];

export function PrepCourseRegistrationWalkthrough() {
  const { setWalkthroughViewed } = useWalkthroughContext();

  return (
    <Walkthrough
      run
      steps={steps}
      continuous
      onComplete={setWalkthroughViewed('PREP_COURSE_REGISTRATION')}
      showSkipButton
    />
  );
}
