import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { CallBackProps } from 'react-joyride';

import { ROUTES } from '@/_navigation';
import { useLocationDetails } from '@/_navigation/hooks/use-location-details';
import { useMeContext } from '@/_providers/me-provider';
import { CookieManager } from '@/_services';

import { WalkthroughContext } from './context';
import type { WalkthroughContextType, WalkthroughType, Walkthroughs } from './types';

const WALKTHROUGHS: Record<WalkthroughType, number> = {
  PREP_COURSE_REGISTRATION: 0,
  CLASS_SCHEDULE: 1,
  PREP_COURSE: 2,
  PASS_COURSE: 3,
  LESSON_LIVE_INDICATOR: 4,
  COURSE_TABS: 5,
};

const MAX_WALKTHROUGHS = 5;

export function WalkthroughProvider({ children }: { children: ReactNode }) {
  const routeData = useLocationDetails();

  const [walkthroughs, setWalkthroughs] = useState<Walkthroughs>({});
  // const { showOnboardingVideo } = useOnboardingContext();

  const { data } = useMeContext();

  const setWalkthroughViewed = useMemo(() => setWalkthroughViewedHoc(data?.id || ''), [data?.id]);
  const getWalkthroughViewed = useMemo(() => getWalkthroughViewedHoc(data?.id || ''), [data?.id]);

  const setCurrentWalkthroughStorage = useMemo(() => setCurrentWalkthroughStorageHoc(data?.id || ''), [data?.id]);
  const getCurrentWalkthroughStorage = useMemo(() => getCurrentWalkthroughStorageHoc(data?.id || ''), [data?.id]);

  const [currentWalkthrough, setCurrentWalkthrough] = useState<number>(getCurrentWalkthroughStorage());

  const handleWalkthroughCompleted = useCallback(
    (walkthroughType: WalkthroughType) => {
      setWalkthroughViewed(walkthroughType);
      setWalkthroughs((prev) => ({ ...prev, [walkthroughType]: { ...prev[walkthroughType], show: false } }));
      setCurrentWalkthrough(currentWalkthrough + 1);
    },
    [setWalkthroughViewed, currentWalkthrough]
  );

  const startWalkthrough = useCallback(
    (walkthroughType: WalkthroughType) => {
      if (getWalkthroughViewed(walkthroughType) || currentWalkthrough >= MAX_WALKTHROUGHS) {
        return;
      }

      setCurrentWalkthroughStorage(currentWalkthrough);
      setWalkthroughs((prev) => ({ ...prev, [walkthroughType]: { type: walkthroughType, show: true } }));
    },
    [getWalkthroughViewed, setCurrentWalkthroughStorage, currentWalkthrough]
  );

  const skipAll = useCallback(() => {
    setCurrentWalkthrough(MAX_WALKTHROUGHS);
    setCurrentWalkthroughStorage(MAX_WALKTHROUGHS);
    setWalkthroughs({});
  }, [setCurrentWalkthroughStorage]);

  const handleWalkthroughViewed = useCallback(
    (walkthroughType: WalkthroughType) => (data: CallBackProps) => {
      if (data.status === 'skipped') {
        skipAll();
        return;
      }
      handleWalkthroughCompleted(walkthroughType);
    },
    [handleWalkthroughCompleted, skipAll]
  );

  useEffect(() => {
    if (currentWalkthrough >= MAX_WALKTHROUGHS) {
      return;
    }

    const keys = Object.keys(WALKTHROUGHS) as WalkthroughType[];
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (!getWalkthroughViewed(key)) {
        if (i >= currentWalkthrough) {
          if (key === 'CLASS_SCHEDULE' && routeData.data.path !== ROUTES.UNREGISTERED_DETAILS.path) {
            continue;
          }
          startWalkthrough(key);
          break;
        } else if (key === 'CLASS_SCHEDULE' && routeData.data.path === ROUTES.UNREGISTERED_DETAILS.path) {
          startWalkthrough(key);
          break;
        }
      }
    }
  }, [currentWalkthrough, getWalkthroughViewed, startWalkthrough, routeData]);

  const value: WalkthroughContextType = useMemo(
    () => ({
      walkthroughs,
      setWalkthroughViewed: handleWalkthroughViewed,
      startWalkthrough,
    }),
    [walkthroughs, startWalkthrough, handleWalkthroughViewed]
  );

  return <WalkthroughContext.Provider value={value}>{children}</WalkthroughContext.Provider>;
}

function setWalkthroughViewedHoc(userId: string) {
  return (type: WalkthroughType) => CookieManager.set(`walkthrough-viewed-${userId}-${type}`, 'true');
}

function getWalkthroughViewedHoc(userId: string) {
  return (type: WalkthroughType) => !!CookieManager.get(`walkthrough-viewed-${userId}-${type}`);
}

function setCurrentWalkthroughStorageHoc(userId: string) {
  return (index: number) => {
    return localStorage.setItem(`current-walkthrough-${userId}`, index.toString());
  };
}

function getCurrentWalkthroughStorageHoc(userId: string) {
  return () => parseFloat(localStorage.getItem(`current-walkthrough-${userId}`) || '0');
}
