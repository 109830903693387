import { Outlet } from 'react-router';

import { useMeContext } from '@/_providers';

import { Header } from './header';
import { NotificationBar, NotificationsProvider } from './notifications';

export default function LayoutWithHeader() {
  const { canAccessPortalFeatures } = useMeContext();

  return (
    <NotificationsProvider>
      <Header />
      {canAccessPortalFeatures && <NotificationBar />}
      <Outlet />
    </NotificationsProvider>
  );
}
