import { route, string, boolean, number, union } from 'react-router-typesafe-routes/dom';

import type { IRouteModel } from './types';

// !important - this is file should be used only for routes settings
// !important - Please add a type for each route in src/_navigation/types.ts before creating a new route

const ROUTES = {
  MAIN: route('', {}, { META: { name: 'Main', routeType: ['PUBLIC'] } }),

  ACTIVATE_USER: route(
    'activate',
    {},
    {
      META: {
        name: 'Activate User',
        routeType: ['PUBLIC'],
      },
    }
  ),

  FORGET_PASSWORD: route(
    'forgot-password',
    {},
    {
      META: {
        name: 'Forget Password',
        routeType: ['PUBLIC'],
      },
    }
  ),

  RESET_PASSWORD: route(
    'reset-password',
    {},
    {
      META: {
        name: 'Reset Password',
        routeType: ['PUBLIC'],
      },
    }
  ),

  TEST: route(
    'test',
    {},
    {
      META: {
        name: 'Test',
        routeType: ['PUBLIC'],
      },
    }
  ),

  STUDY_GUIDES: route(
    'study-guides',
    {},
    {
      META: {
        name: 'Curriculum',
        routeType: ['PUBLIC'],
      },
    }
  ),

  LOGIN: route(
    'login',
    {},
    {
      META: {
        name: 'Login',
        routeType: ['PUBLIC'],
      },
    }
  ),

  INSTRUCTOR_LOGIN: route(
    'instructor',
    {},
    {
      META: {
        name: 'Instructor Login',
        routeType: ['PUBLIC'],
      },
    }
  ),

  BRIDGE_PLAN: route(
    'bridge-plan',
    {},
    {
      META: {
        name: 'Bridge Plan',
        routeType: ['PUBLIC'],
      },
    }
  ),

  RESOLVE_LOGIN: route(
    'resolve-login',
    {
      state: { naturalBack: boolean().default(false), from: string() },
    },
    {
      META: {
        name: 'Resolve Login',
        routeType: ['PREP'],
      },
    }
  ),

  LOGOUT: route(
    'logout',
    {},
    {
      META: {
        name: 'Logout',
        routeType: ['PREP'],
      },
    }
  ),

  ONBOARDING_WELCOME: route(
    'onboarding-welcome',
    {},
    {
      META: {
        name: 'Welcome',
        routeType: ['PREP'],
      },
    }
  ),

  ONBOARDING_WHY_BRIDGE: route(
    'onboarding-why-bridge',
    {},
    {
      META: {
        name: 'Video',
        routeType: ['PREP'],
      },
    }
  ),

  ONBOARDING_ADVISOR: route(
    'onboarding-advisor',
    {},
    {
      META: {
        name: 'Advisors',
        routeType: ['PREP'],
      },
    }
  ),

  ONBOARDING_INFORMATION: route(
    'onboarding-information',
    {},
    {
      META: {
        name: 'Information',
        routeType: ['PREP'],
      },
    }
  ),

  ONBOARDING_HOW_TO: route(
    'onboarding-how-to',
    {},
    {
      META: {
        name: 'How to?',
        routeType: ['PREP'],
      },
    }
  ),

  ON_DEMAND_TUTORING: route(
    'on-demand-tutoring',
    {
      searchParams: { course_id: string() },
    },
    {
      META: {
        name: 'On Demand Tutoring',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  ON_DEMAND_TUTORING_AWAITING: route(
    'on-demand-tutoring/awaiting-answer',
    {
      searchParams: { course_id: string() },
      state: { naturalBack: boolean().default(false) },
    },
    {
      META: {
        name: 'Awaiting Answer',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  ON_DEMAND_TUTORING_ANSWERED: route(
    'on-demand-tutoring/answered',
    {
      searchParams: { course_id: string() },
    },
    {
      META: {
        name: 'Answered',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  ON_DEMAND_TUTORING_SOLVED: route(
    'on-demand-tutoring/solved',
    {
      searchParams: { course_id: string() },
    },
    {
      META: {
        name: 'Solved',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  ON_DEMAND_TUTORING_QUESTION_ASK: route(
    'on-demand-tutoring/ask',
    {
      searchParams: { course_id: string() },
      state: { naturalBack: boolean().default(false), from: string() },
    },
    {
      META: {
        name: 'Ask a Question',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  ON_DEMAND_TUTORING_QUESTION: route(
    'on-demand-tutoring/questions/:id',
    {
      params: { id: string().defined() },
      searchParams: { course_id: string() },
      state: { naturalBack: boolean().default(false) },
    },
    {
      META: {
        name: 'View Question',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  ON_DEMAND_TUTORING_QUESTION_EDIT: route(
    'on-demand-tutoring/questions/:id/edit',
    {
      params: { id: string().defined() },
      searchParams: {
        course_id: string().defined(),
        parent_question_id: string().defined(),
      },
    },
    {
      META: {
        name: 'Edit Question',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  BOOK_1_ON_1_SESSION: route(
    'book-1-on-1-session',
    {
      state: { courseId: string(), naturalBack: boolean().default(false) },
    },
    {
      META: {
        name: 'Schedule Tutoring',
        routeType: ['PREP'],
      },
    }
  ),

  CONFIRM_1_ON_1_SESSION: route(
    'confirm-1-on-1-session-ycbm',
    {},
    {
      META: {
        name: 'Confirm Schedule',
        routeType: ['PREP'],
      },
    }
  ),

  REWARDS: route(
    'rewards',
    {},
    {
      META: {
        name: 'Rewards',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  STUDY_GUIDES_PURCHASED: route(
    'study-guides/purchased',
    {},
    {
      META: {
        name: 'Curriculum',
        routeType: ['PREP'],
      },
    }
  ),

  STUDY_GUIDES_AVAILABLE: route(
    'study-guides/available',
    {},
    {
      META: {
        name: 'Available Study Guides',
        routeType: ['PREP'],
      },
    }
  ),

  COURSES: route(
    'courses',
    {},
    {
      META: {
        name: 'Courses',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  COURSE_PRINT_BOOK: route(
    'courses/print-course-book/:id',
    {
      params: { id: string().defined() },
      state: {
        id: string(),
        from: string(),
        alreadyHasShipped: boolean(),
        naturalBack: boolean().default(false),
      },
    },
    {
      META: {
        name: 'Print Course Book',
        routeType: ['PREP'],
      },
    }
  ),

  COURSE_SURVEY: route(
    'courses/survey/:surveyType/:surveyId',
    {
      params: { surveyId: string().defined(), surveyType: number().defined() },
    },
    {
      META: {
        name: 'Course Survey',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  UNREGISTERED: route(
    'courses/unregistered',
    {
      state: { from: string() },
    },
    {
      META: {
        name: 'Unregistered',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  UNREGISTERED_DETAILS: route(
    'courses/unregistered/:id',
    {
      params: { id: string().defined() },
      state: { isOdt: boolean().default(false) },
    },
    {
      META: {
        name: 'Course Details',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  IN_PROGRESS: route(
    'courses/in-progress',
    {
      state: {
        id: string(),
        from: string(),
        askForReview: boolean().default(false),
      },
    },
    {
      META: {
        name: 'In Progress',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  IN_PROGRESS_DETAILS: route(
    'courses/in-progress/:id',
    { params: { id: string().defined() } },
    {
      META: {
        name: 'Course Details',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  COMPLETE: route(
    'courses/completed',
    {},
    {
      META: {
        name: 'Completed',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  COMPLETE_DETAILS: route(
    'courses/complete/:id',
    { params: { id: string().defined() } },
    {
      META: {
        name: 'Course Details',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  BILLING: route(
    'billing',
    {},
    {
      META: {
        name: 'Billing',
        routeType: ['PREP'],
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  BILLING_HISTORY: route(
    'billing/history',
    {},
    {
      META: {
        name: 'Payment History',
        routeType: ['PREP'],
      },
    }
  ),

  BILLING_PAYMENT_METHODS: route(
    'billing/payment-methods',
    {
      state: {
        naturalBack: boolean().default(false),
        from: string(),
        id: string(),
        isFromCourseRegistration: boolean().default(false),
        add: union(['ach', 'cc']),
      },
    },
    {
      META: {
        name: 'Payment Methods',
        routeType: ['PREP'],
      },
    }
  ),

  BILLING_MAKE_A_PAYMENT: route(
    'billing/make-a-payment',
    {},
    {
      META: {
        name: 'Make a Payment',
        routeType: ['PREP'],
      },
    }
  ),

  PROFILE: route(
    'profile',
    {},
    {
      META: {
        name: 'Profile',
        routeType: ['PREP'],
      },
    }
  ),

  PROFILE_EDIT: route(
    'profile/edit',
    {
      state: {
        naturalBack: boolean(),
        from: string(),
        id: string(),
        isFromCourseRegistration: boolean().default(false),
      },
    },
    {
      META: {
        name: 'Edit Profile',
        routeType: ['PREP'],
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  PROFILE_COURSES: route(
    'profile/courses',
    {},
    {
      META: {
        name: 'Courses',
        routeType: ['PREP'],
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  PROFILE_CHANGE_PASSWORD: route(
    'profile/update-password',
    {},
    {
      META: {
        name: 'Change Password',
        routeType: ['PREP'],
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  TERMS: route(
    'terms',
    {},
    {
      META: {
        name: 'Main',
        routeType: ['PREP'],
      },
    }
  ),

  CONTACT_SUPPORT: route(
    'contact-support',
    {},
    {
      META: {
        name: 'Contact Support',
        routeType: ['PREP'],
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  MEET_AN_ADVISOR: route(
    'contact-support/meet-with-an-advisor',
    {
      state: { from: string(), naturalBack: boolean().default(false) },
    },
    {
      META: {
        name: 'Meet with an advisor',
        routeType: ['PREP'],
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  ACCESS_BLOCKED: route(
    'access-blocked',
    {},
    {
      META: {
        name: 'Access Blocked',
        routeType: ['PREP'],
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  HARDSHIP_PLANS: route(
    'hardship/plans',
    {},
    {
      META: {
        name: 'Hardship Plans',
        routeType: ['PREP'],
        shouldBlockPPPAccess: false,
        shouldAllowPrepAccessOnly: true,
      },
    }
  ),

  JOIN_LESSON_WAITING: route(
    'join-lesson-waiting',
    {
      state: {
        meetingId: string(),
        email: string(),
        instructor_getting_late: string(),
        id: string(),
      },
    },
    {
      META: {
        name: 'Join Lesson Waiting',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
        shouldAllowPrepAccessOnly: true,
        isFullScreen: true,
      },
    }
  ),

  JOIN_LESSON: route(
    'join-lesson',
    {
      state: {
        userName: string(),
        userEmail: string(),
        leaveUrl: string(),
        signature: string(),
        id: number(),
        pwd: string(),
      },
    },
    {
      META: {
        name: 'Join Lesson',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
        shouldAllowPrepAccessOnly: true,
        isFullScreen: true,
      },
    }
  ),

  REFERRAL_PROGRAM: route(
    'refer',
    {},
    {
      META: {
        name: 'Referral Program',
        routeType: ['PUBLIC'],
      },
    }
  ),

  REWARDS_SURVEY: route(
    'rewards/survey',
    {},
    {
      META: {
        name: 'Rewards Survey',
        routeType: ['PREP'],
        shouldBlockPPPAccess: true,
      },
    }
  ),

  POST_TUTORING_SURVEY: route(
    'post-tutoring-survey',
    {},
    {
      META: {
        name: 'Post Tutoring Survey',
        routeType: ['PREP'],
      },
    }
  ),

  AFTER_CALL_SURVEY: route(
    'after-call-survey',
    {},
    {
      META: {
        name: 'After Call Survey',
        routeType: ['PREP'],
      },
    }
  ),

  ONBOARDING_SELF_SURVEY: route(
    'onboarding-self-survey',
    {},
    {
      META: {
        name: 'Onboarding Self Survey',
        routeType: ['PREP'],
      },
    }
  ),

  NOTIFICATIONS: route(
    'notifications',
    {},
    {
      META: {
        name: 'Notifications',
        routeType: ['PREP'],
      },
    }
  ),
};

export type RoutesType = typeof ROUTES;
export type RoutesKeyType = keyof RoutesType;
export type RouteRecordsType = ReturnType<typeof route<{ META: IRouteModel }>>;
export default ROUTES;
