import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router';

import { ErrorBoundary } from '@/_components';
import { config } from '@/_config';
import { LayoutWithHeader, LayoutWithConfigurations } from '@/_containers';
import { RedirectionResolver, ROUTES } from '@/_navigation';
import { AfterCallSurvey, PostTutoringSurvey, SelfOnboardingSurvey } from '@/_other-pages/surveys';
import RewardsSurvey from '@/_prep-portal/rewards-survey';
import { RouteRedirectionProvider } from '@/_providers/route-redirection-provider';

import {
  ForgetPassword,
  InProgressDetail,
  Login,
  Profile,
  ProfileEdit,
  ProfileCourses,
  ProfileChangePassword,
  UnregisteredDetails,
  Rewards,
  OnDemandTutoring,
  // CourseCompleteDetails,
  Billing,
  BillingHistory,
  BillingMakeAPayment,
  BillingPaymentMethods,
  Courses,
  Terms,
  ContactSupport,
  MeetAnAdvisor,
  ResetPassword,
  CourseSurvey,
  CoursePrintBook,
  OnDemandTutoringQuestionEdit,
  OnDemandTutoringQuestionAsk,
  OnDemandTutoringQuestion,
  BookOneOnOneSession,
  JoinLesson,
  JoinLessonWaiting,
  ReferralProgram,
  RedirectExternal,
  ConfirmOneOnOneSession,
  BookingsProvider,
  Test,
  ActivateUser,
  Welcome,
  AdvisorIntro,
  CustomerInformation,
  HowTo,
  WhyBridgeIntro,
} from './_prep-portal';
import { ResolveLogout } from './_prep-portal/auth/logout';
import { ResolveLogin } from './_prep-portal/auth/resolve-login';
import { MyStudyGuides, StudyGuides, BuyStudyGuides } from './_study-guides';
import Administrator from './administrator';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={ROUTES.INSTRUCTOR_LOGIN.relativePath}
        element={<RedirectExternal to={config.instructorPortalLink} />}
      />
      <Route path={ROUTES.BRIDGE_PLAN.relativePath} element={<RedirectExternal to={config.bridgePlanLink} />} />

      <Route errorElement={<ErrorBoundary />}>
        <Route path={ROUTES.MAIN.relativePath} element={<Administrator />}>
          <Route path={ROUTES.AFTER_CALL_SURVEY.relativePath} element={<AfterCallSurvey />} />

          <Route element={<RouteRedirectionProvider />}>
            <Route path={ROUTES.TEST.relativePath} element={<Test />} />
            <Route path={ROUTES.LOGIN.relativePath} element={<Login />} />
            <Route path={ROUTES.FORGET_PASSWORD.relativePath} element={<ForgetPassword />} />
            <Route path={ROUTES.RESET_PASSWORD.relativePath} element={<ResetPassword />} />
            <Route path={ROUTES.ACTIVATE_USER.relativePath} element={<ActivateUser />} />
            <Route path={ROUTES.REFERRAL_PROGRAM.relativePath} element={<ReferralProgram />} />

            <Route path={ROUTES.STUDY_GUIDES.relativePath} element={<StudyGuides />} />

            <Route element={<LayoutWithConfigurations />}>
              <Route path={ROUTES.RESOLVE_LOGIN.relativePath} element={<ResolveLogin />} />
              <Route path={ROUTES.LOGOUT.relativePath} element={<ResolveLogout />} />

              <Route path={ROUTES.ONBOARDING_SELF_SURVEY.relativePath} element={<SelfOnboardingSurvey />} />

              <Route path={ROUTES.ONBOARDING_SELF_SURVEY.relativePath} element={<SelfOnboardingSurvey />} />
              <Route path={ROUTES.ONBOARDING_WELCOME.relativePath} element={<Welcome />} />
              <Route path={ROUTES.ONBOARDING_WHY_BRIDGE.relativePath} element={<WhyBridgeIntro />} />
              <Route path={ROUTES.ONBOARDING_ADVISOR.relativePath} element={<AdvisorIntro />} />
              <Route path={ROUTES.ONBOARDING_INFORMATION.relativePath} element={<CustomerInformation />} />
              <Route path={ROUTES.ONBOARDING_HOW_TO.relativePath} element={<HowTo />} />

              <Route path={ROUTES.JOIN_LESSON_WAITING.relativePath} element={<JoinLessonWaiting />} />
              <Route path={ROUTES.JOIN_LESSON.relativePath} element={<JoinLesson />} />
              <Route path={ROUTES.POST_TUTORING_SURVEY.relativePath} element={<PostTutoringSurvey />} />

              <Route element={<LayoutWithHeader />}>
                <Route path={ROUTES.REWARDS_SURVEY.relativePath} element={<RewardsSurvey />} />
                <Route path={ROUTES.REWARDS.relativePath} element={<Rewards />} />

                <Route
                  path={ROUTES.ON_DEMAND_TUTORING_QUESTION_ASK.relativePath}
                  element={<OnDemandTutoringQuestionAsk />}
                />

                <Route
                  path={ROUTES.ON_DEMAND_TUTORING_QUESTION_EDIT.relativePath}
                  element={<OnDemandTutoringQuestionEdit />}
                />

                <Route path={ROUTES.ON_DEMAND_TUTORING_QUESTION.relativePath} element={<OnDemandTutoringQuestion />} />

                {[
                  ROUTES.ON_DEMAND_TUTORING,
                  ROUTES.ON_DEMAND_TUTORING_AWAITING,
                  ROUTES.ON_DEMAND_TUTORING_ANSWERED,
                  ROUTES.ON_DEMAND_TUTORING_SOLVED,
                ].map((item) => (
                  <Route key={item.relativePath} path={item.relativePath} element={<OnDemandTutoring />} />
                ))}

                <Route path={ROUTES.PROFILE_CHANGE_PASSWORD.relativePath} element={<ProfileChangePassword />} />

                <Route path={ROUTES.PROFILE_COURSES.relativePath} element={<ProfileCourses />} />

                <Route path={ROUTES.BILLING_PAYMENT_METHODS.relativePath} element={<BillingPaymentMethods />} />

                <Route path={ROUTES.BILLING_HISTORY.relativePath} element={<BillingHistory />} />

                <Route path={ROUTES.BILLING_MAKE_A_PAYMENT.relativePath} element={<BillingMakeAPayment />} />

                <Route path={ROUTES.BILLING.relativePath} element={<Billing />} />

                <Route path={ROUTES.PROFILE.relativePath} element={<Profile />} />
                <Route path={ROUTES.PROFILE_EDIT.relativePath} element={<ProfileEdit />} />

                <Route path={ROUTES.TERMS.relativePath} element={<Terms />} />

                <Route path={ROUTES.COURSE_SURVEY.relativePath} element={<CourseSurvey />} />

                <Route path={ROUTES.COURSE_PRINT_BOOK.relativePath} element={<CoursePrintBook />} />

                <Route path={ROUTES.UNREGISTERED_DETAILS.relativePath} element={<UnregisteredDetails />} />

                <Route path={ROUTES.IN_PROGRESS_DETAILS.relativePath} element={<InProgressDetail />} />

                <Route path={ROUTES.STUDY_GUIDES_PURCHASED.relativePath} element={<MyStudyGuides />} />
                <Route path={ROUTES.STUDY_GUIDES_AVAILABLE.relativePath} element={<BuyStudyGuides />} />

                {[ROUTES.UNREGISTERED, ROUTES.COURSES, ROUTES.IN_PROGRESS, ROUTES.COMPLETE].map((item) => (
                  <Route path={item.relativePath} key={item.relativePath} element={<Courses />} />
                ))}

                <Route element={<BookingsProvider />}>
                  <Route path={ROUTES.CONTACT_SUPPORT.relativePath} element={<ContactSupport />} />
                  <Route path={ROUTES.MEET_AN_ADVISOR.relativePath} element={<MeetAnAdvisor />} />
                  <Route path={ROUTES.BOOK_1_ON_1_SESSION.relativePath} element={<BookOneOnOneSession />} />
                  <Route path={ROUTES.CONFIRM_1_ON_1_SESSION.relativePath} element={<ConfirmOneOnOneSession />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route index element={<RedirectionResolver />} />
          <Route path="*" element={<RedirectionResolver />} />
        </Route>
      </Route>
    </>
  )
);

export default router;
