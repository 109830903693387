import { GoogleOAuthProvider } from '@react-oauth/google';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { AtpLib } from 'atp-react-ui';
import { RouterProvider } from 'react-router/dom';

import { config } from '@/_config';
import { persistOptions, queryClient } from '@/_services';

import router from './root-router';

function EntryPoint() {
  return (
    <AtpLib usePreferences={false}>
      <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
        <GoogleOAuthProvider clientId={config.googleLoginClientId}>
          <RouterProvider router={router} />
        </GoogleOAuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </PersistQueryClientProvider>
    </AtpLib>
  );
}

export default EntryPoint;
